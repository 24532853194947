import React from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.css'
import styled, { css } from 'styled-components'
import sitelogo from '../images/ui-ux-tips-logo.svg';
import EmailSignup from '../components/email-signup/email-signup-footer'

// Markup
export default () => (

  <Footer>

    <FooterSectionContainer className="footer-section container boxed-width" id="page-footer">

      <FooterBlock>
        <FooterInner className="container">

          <FooterColumn className="logo-col col-3 col-12-t">
            <Logo href="/" className="logo">
              <LogoImg src={sitelogo} />
            </Logo>
            <LogoStrapline>
              Become a better designer with UI and UX design tips.
            </LogoStrapline>
          </FooterColumn>

          <FooterColumn className="col-3 col-12-t">
            <h3>Quick links</h3>
            <FooterMenu>
            <li><StyledLink to="/" className="nav-link">Home</StyledLink></li>
            <li><StyledLink to="/tips/" className="nav-link">Tips</StyledLink></li>
            <li><StyledLink to="/videos/" className="nav-link">Videos</StyledLink></li>
            <li><StyledLink to="/signup/" className="nav-link">Subscribe</StyledLink></li>
            </FooterMenu>
          </FooterColumn>

          <FooterColumn className="col-3 col-12-t">
            <h3>More</h3>
            <FooterMenu>
            <li><StyledLink to="/contact/" className="nav-link">Contact me</StyledLink></li>
            <li><StyledLink to="/about/" className="nav-link">About</StyledLink></li>
            <li><StyledLink to="/open-startup/" className="nav-link">Open startup details</StyledLink></li>
            <li><StyledLink to="/sponsor-site/" className="nav-link">Sponsor the site</StyledLink></li>
            </FooterMenu>
          </FooterColumn>

        </FooterInner>
      </FooterBlock>

    </FooterSectionContainer>

    <BottomImg src="/content/watercolour-footer.png" />
  </Footer>
)

// Styles

const Footer = styled.footer `
  width: 100%;
  padding-top: 72px;
  padding-bottom: 72px;
  margin-top: 72px;
  position: relative;
`
const FooterSectionContainer = styled.div `
  position: relative;
  z-index: 1;
`

// Footer Wrapper
const FooterBlock = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
  width: 100%;
`;

const FooterInner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
  align-items: flex-start;
`;

// Footer Column
const FooterColumn = styled.div `

  &.logo-col {
    margin-right: auto;
  }
`

const FooterH3 = styled.h3 `
  color: #270D8F;
  font-size: 16px;
`

const FooterMenu = styled.ul `
  padding-left: 0;
  display: flex;
  flex-direction: column;
`

const StyledListItem = styled.li`
  margin-left: 0;
`

const StyledLink = styled(props => <Link {...props} />)`
  color: #9C9C9C;
  text-decoration: none;

  &:hover { 
    color: #fff;
  }
`;

// Bottom Footer

const LogoStrapline = styled.p`
  text-align: left;
`

const EmojiSpan = styled.span`
  padding-left: 2px;
`

// Logo
const Logo = styled.a`
  width: 100%;
  max-width: 104px;
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

const LogoImg = styled.img`
  max-width: 104px;
  width: auto;
`


const BottomImg = styled.img `
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
`