import React, { Component } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'
import Mailchimp from 'react-mailchimp-form'

// Markup
class App extends Component {
  render() {
    return (
        <EmailContainer className="container justify-content-center is-column col-6 col-12-t">

          <TopColumn className="col-12">
            <EmailSignupTitle>Subscribe for new tips, tutorials, and resources.</EmailSignupTitle>
            <EmailSignupParagraph>We will only send quality content, no spam. Subscribe and we'll send you the latest tips when they are available.</EmailSignupParagraph>
          </TopColumn>

          <BottomColumn className="col-12">
            <EmailWrapper>
              <Mailchimp
              action='https://hotmail.us3.list-manage.com/subscribe/post?u=ac98385643a5c820cc83619c4&amp;id=ddb4fc0213'
              fields={[
                {
                  name: 'EMAIL',
                  placeholder: 'Email',
                  type: 'email',
                  required: true
                }
              ]}

              // Change predetermined language
                messages = {
                  {
                    sending: "Sending... 🎁",
                    success: "Thank you for subscribing! 🎉",
                    error: "An unexpected internal error has occurred. 🤦‍♂️",
                    empty: "You must enter an e-mail address. 💌",
                    duplicate: "Too many subscribe attempts for this email address, cheeky. 🤙",
                    button: "Subscribe"
                  }
                }
                // Add a personalized class
                className='mailchimp-form'

              />
            </EmailWrapper>
          </BottomColumn>

        </EmailContainer>
    );
  }
}
 
export default App;

// Styles
const EmailContainer = styled.div`
  max-width: 100%;
`

const EmailWrapper = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  align-items: center;

  form {
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
  }

  input {
    border: none;
    font-size: 16px;
    width: auto;
    height: 48px;
    padding: 24px;
    border-radius: 8px;
    background-color: transparent;
    border: solid 2px #fff;
    flex: 1;
    margin-right: 24px;
    font-family: "HarmoniaSans W01", "Raleway", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #fff;

    @media (max-width: 768px) {
      margin-bottom: 24px;
      margin-right: 0;
    }
  }

  button {
    border: none;
    background-color: #fff;
    color: #1a1a1a;
    font-weight: 400;
    font-size: 16px;
    padding: 12px 24px;
    border-radius: 8px;
    margin-left: auto;
    border-color: #fff;
    cursor: pointer;
    transition: all 240ms ease-in-out;
    max-width: 100%;

    &:hover {
      background-color: #aaa;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .msg-alert {
    width: 66.6%;
    font-size: 12px;
    position: static;
    height: auto;
    display: flex;
    align-items: center;
    margin-top: 24px;

    p {
      margin-bottom: 0;
      color: #fff !important;

      @media (max-width: 768px) {
        margin-bottom: 24px;
      }
    }
  }
`

const TopColumn = styled.div `
`

const BottomColumn = styled.div `
`

const EmailSignupTitle = styled.h3 `
  color: #fff;
`

const EmailSignupParagraph = styled.p `
  color: #9C9C9C;
`