import React from 'react'
import { Link } from 'gatsby'
import base from './base.css'
import grid from './global-styles/grid.scss'
import newBase from './global-styles/base.scss'
import Container from './container'
import Header from './header'
import Navigation from './navigation'
import Footer from './footer'
import styled, { css } from 'styled-components'

class Template extends React.Component {
  render() {
    const { location, children } = this.props
    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <LayoutWrapper>
        <Container>
          {children}
        </Container>
        <Footer />
      </LayoutWrapper>
    )
  }
}

export default Template

// Styles

const LayoutWrapper = styled.div `
`