import React from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.css'
import styled, { css } from 'styled-components'
import sitelogo from '../images/ui-ux-tips-logo.svg';

// Markup
export default () => (
  <Header>
    <HeaderInner className="header-inner boxed-width">

      <Logo href="/" className="logo">
        <LogoImg src={sitelogo} alt="UI UX Tips logo"/>
      </Logo>

      <NavWrapper role="navigation">
        <ul className={styles.navigation}>
          <StyledListItem className={styles.navigationItem}>
            <StyledLink to="/" className="nav-link">Home</StyledLink>
            <StyledLink to="/tips/" className="nav-link">Tips</StyledLink>
            <StyledLink to="/videos/" className="nav-link">Videos</StyledLink>
          </StyledListItem>
        </ul>
      </NavWrapper>

      <NavInner>

        <NavButton to="/signup" className="button">Subscribe <span>for updates</span> <NavButtonImage src="/content/wave.png" alt="Nav button wave emoji"/></NavButton>

      </NavInner>

    </HeaderInner>
  </Header>
)

// Styles
const Header = styled.header `
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
`;

const HeaderInner = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 48px;
  padding-bottom: 24px;
  box-sizing: border-box;
  transition: all ease-in-out 300ms;
`;

const Logo = styled.a `
  max-width: 150px;
  width: 100%;
  margin-right: 96px;
  margin-bottom: 0;

  @media (max-width: 768px) {
    margin-right: 24px;
  }
`;

const LogoImg = styled.img `
  width: auto;
`

const NavWrapper = styled.nav `
  margin-left: auto;
  margin-right: auto;
`

const StyledListItem = styled.li `
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    // margin-right: 0;
  }
`

const StyledLink = styled(props => <Link {...props} />) `
  text-decoration: none;
  margin-bottom: 0;
  margin-right: 48px;

  &:last-child {
    margin-right: 0;
  }
`;

const NavButton = styled(props => <Link {...props} />) `
  span {
    margin-left: 6px;

    @media (max-width: 768px) {
      display: none;
    }
  }
`

const NavButtonImage = styled.img `
  width: 18px;
  margin-left: 12px;
`

const NavInner = styled.div `
  display: flex;
  align-items: center;
`